import React, {useState} from "react";
import {Container, Row, Col, Fade} from "react-bootstrap";
import pictureMomentum from "./pictureMomentum.png"
import groceryBG from "./groceryBG2.png"
import automatedBG_1920 from "./automatedBG_1920.png"
import CTFChallenge from "./CTFChallenge.jpg"
import Card from 'react-bootstrap/Card';




const Projects = () => {

    const [cardLoaded1, setCardLoaded1] = useState(false);
    const [cardLoaded2, setCardLoaded2] = useState(false);
    const [cardLoaded3, setCardLoaded3] = useState(false);
    const [cardLoaded4, setCardLoaded4] = useState(false);


    return (
        <>
            <Container className="my-5">
            <Row xs={1}  md={2} className="g-5">
                    <Col>
                        <Fade in={cardLoaded1} dimension="width">
                            <Card onLoad={() => setCardLoaded3(!cardLoaded3)}  border="dark" className="bg-light">
                                <Card.Body>
                                    <h3 className="color_sec text-center">Propositional Logic - Java Toolkit</h3>
                                    <Card.Text className="mt-5">
                                        <ul>
                                            <li>Formula transformations in CNF/DNF/NNF</li>
                                            <li>Satisfiability tests with model generation</li>
                                            <li>Computation of an interpolant</li>
                                            <li>Redundancy test for clauses and clause sets</li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                                <a href="https://github.com/TimMerker/AutomatedReasoning">
                                    <Card.Img variant="bottom" src={automatedBG_1920}/>
                                </a>
                            </Card>
                        </Fade>
                    </Col>
                <Col>
                    <Fade in={cardLoaded2} dimension="width">
                        <Card onLoad={() => setCardLoaded2(!cardLoaded2)}  border="dark" className="bg-light">
                            <Card.Body>
                                <h3 className="color_sec text-center">Grocery List - Android App in Java</h3>
                                <Card.Text className="mt-5">
                                    <ul>
                                        <li>Mobile Grocery List for Android</li>
                                        <li>English and German language support</li>
                                        <li>Scalable architecture</li>
                                        <li>Offline support</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                            <a href="https://github.com/TimMerker/GroceryList">
                            <Card.Img variant="bottom" src={groceryBG} />
                            </a>

                        </Card>
                    </Fade>
                </Col>
                <Col>
                    <Fade in={cardLoaded3} dimension="width">
                        <Card onLoad={() => setCardLoaded1(!cardLoaded1)} border="dark" className="bg-light">
                            <Card.Body>
                                <h3 className="color_sec text-center">Momentum Stock Scanner in R</h3>
                                <Card.Text className="mt-5">
                                    <ul>
                                        <li>Scanning stocks using Yahoo Finance and R</li>
                                        <li>Measuring momentum of stocks</li>
                                        <li>Creating portfolios based on highest momentum</li>
                                        <li>Comparing portfolios with S&P 500</li>
                                    </ul>
                                    {/*                                  In <b>Financial Data Science</b>, I was the project lead for the development of a stock
                                    scanner in <b>R</b>.
                                    Our group implemented different approaches for measuring the momentum of current price
                                    developments and evaluated the results.
                                    We aced our research internship.*/}
                                </Card.Text>
                            </Card.Body>
                            <a href="https://github.com/TimMerker/Momentum-Stock-Scanner-in-R">
                                <Card.Img variant="bottom" src={pictureMomentum} />
                            </a>
                            {/*
                            <Card.Footer className="text-muted">2 days ago</Card.Footer>
*/}
                        </Card>
                    </Fade>
                </Col>
                <Col>
                    <Fade in={cardLoaded4} dimension="width">
                        <Card onLoad={() => setCardLoaded4(!cardLoaded4)}  border="dark" className="bg-light">
                            <Card.Body>
                                <h3 className="color_sec text-center">Capture The Flag - Cryptography Python</h3>
                                <Card.Text className="mt-5">
                                    <ul>
                                        <li>Design and implementation of Cryptography CTF-Challenge with Python</li>
                                        <li>Highlighting weaknesses in RC4 stream cipher algorithm</li>
                                        <li>Weaknesses in RC4 can be exploited to leak critical data, passwords.</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                            <Card.Img variant="bottom" src={CTFChallenge}/>
                        </Card>
                    </Fade>
                </Col>
            </Row>
            </Container>
        </>
    );
};

export default Projects;