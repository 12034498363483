const contactConfig = {
    title: "Do you have an interesting offer?",
    description: "I am excited to hear about your project, app idea or company. " +
        "If you seek technical advice, consultation, or just want to have a coffee and a chat, don't hesitate to reach out. " +
        "The easiest way to contact me is to use the contact form. Nice to meet you!",
    phone: '+49 1575 8907270',
    email: 'tim.merker98@gmail.com'

};

export {
    contactConfig
};