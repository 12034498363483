import './App.css';
import CollapsibleNavbar from "./components/Navbar/CollapsibleNavbar";
import Footer from "./components/Footer/Footer";
import {HashRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import React from "react";
import Experience from "./pages/Experience/Experience";
import Education from "./pages/Education/Education";
import Projects from "./pages/Projects/Projects";
import Contact from "./pages/Contact/Contact";
import NoPage from "./pages/NoPage";
import LegalNoticeDataProtect from "./pages/ImprintDataProtect/LegalNoticeDataProtect";
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "./ScrollToTop";

export default function App() {
    return (
        <Router>
            <ScrollToTop />
            <div className="App">
                <CollapsibleNavbar/>
                <div className="Main">
                    <Routes>
                        <Route exace path="/" element={<Navigate to="/experience"/>}/>
                        <Route path="/experience" element={<Experience></Experience>}/>
                        <Route path="/projects" element={<Projects></Projects>}/>
                        <Route path="/education" element={<Education></Education>}/>
                        <Route path="/contact" element={<Contact></Contact>}/>
                        <Route path="/legal-notice" element={<LegalNoticeDataProtect></LegalNoticeDataProtect>}/>
                        <Route path="/*" element={<NoPage></NoPage>}/>
                    </Routes>
                </div>
                <Footer/>
            </div>
        </Router>
    );
}
