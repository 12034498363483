export const FooterItems = [

    {
        icon: 'fa-brands fa-github-square',
        url: 'https://github.com/TimMerker',
        cName: 'social-links'
    },
    {
        icon:'fa-brands fa-linkedin',
        url: 'https://www.linkedin.com/in/tim-merker-66843a1a3/',
        cName: 'social-links'
    }
]