import "./Experience.css"
import React from "react";
import {Container} from "react-bootstrap";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {ReactComponent as WorkIcon} from "./briefcase-fill.svg";
import { ReactComponent as Education } from './education.svg';

const Experience = () => {

    return(
        <>
        <Container className="my-5">
                <VerticalTimeline layout="1-column-left" animate={true}>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                        date="2023 - present"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<WorkIcon/>}
                    >
                        <h3 className="vertical-timeline-element-title">Software Development, Thales</h3>
                        <h4 className="vertical-timeline-element-subtitle">Koblenz, Germany</h4>
                        <p>
                            Defense and Security, C++, C#, .NET Framework
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2022 - 2023"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<WorkIcon/>}
                    >
                        <h3 className="vertical-timeline-element-title">Software Development, Debeka</h3>
                        <h4 className="vertical-timeline-element-subtitle">Koblenz, Germany</h4>
                        <p>
                            Java, Client Server Architecture, Back-End
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2020 - 2022"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<WorkIcon />}
                    >
                        <h3 className="vertical-timeline-element-title">Student Teaching Assistant, Computer Networks</h3>
                        <h4 className="vertical-timeline-element-subtitle">Koblenz, Germany</h4>
                        <p>
                            Correction of Homework, Correction and Supervision of Exams
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2019 - 2022"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<WorkIcon />}
                    >
                        <h3 className="vertical-timeline-element-title">Student Teaching Assistant, Logic for Computer Science</h3>
                        <h4 className="vertical-timeline-element-subtitle">Koblenz, Germany</h4>
                        <p>
                            Tutoring, Correction of Homework, Correction and Supervision of Exams
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2017 - 2018"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<WorkIcon />}
                    >
                        <h3 className="vertical-timeline-element-title">Officer Candidate, German Navy</h3>
                        <h4 className="vertical-timeline-element-subtitle">Flensburg, Germany</h4>
                        <p>
                            Basic training, Leadership, Nautical law, Navigation, Officer training
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2021 - 2023"
                        iconStyle={{ background: 'rgb(255,0,0)', color: '#fff' }}
                        contentStyle={{ background: 'rgb(255,0,0)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(255,0,0)' }}
                        icon={<Education />}
                    >
                        <h3 className="vertical-timeline-element-title">M.Sc. Computer Science</h3>
                        <h4 className="vertical-timeline-element-subtitle">Koblenz, Germany</h4>
                        <p>
                            Artificial Intelligence, Big Data, Data Science, Financial Data Analysis, IT-Security
                            , Software Development, Team and Leadership Training, Theoretical Computer Science,
                            Verification, Web Science </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2018 - 2021"
                        iconStyle={{ background: 'rgb(255,0,0)', color: '#fff' }}
                        icon={<Education />}
                    >
                        <h3 className="vertical-timeline-element-title">B.Sc. Computer Science</h3>
                        <h4 className="vertical-timeline-element-subtitle">Koblenz, Germany</h4>
                        <p>
                            Algorithms and Data Structures, Databases, Functional and Logical Programming, IT-Security,
                            Information Systems, Mathematics, Object Oriented Programming, Theoretical Computer
                            Science </p>
                    </VerticalTimelineElement>
                </VerticalTimeline>
            </Container>
        </>
    );
};

export default Experience;
