import React from "react";
import {NavLink} from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "./CollapsibleNavbar.css"


export default function CollapsibleNavbar(){
    return (
        <Navbar collapseOnSelect expand="lg">
                <Navbar.Brand href="/#/experience">
                    <h1>Tim Merker</h1>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav">
                    {/* add icon maybe */}
                </Navbar.Toggle>
                <Navbar.Collapse className="justify-content-end">
                    <Nav >
                        <NavLink className="nav-link" to="/experience">
                            Experience
                        </NavLink>
                        <NavLink className="nav-link" to="/projects">
                            Projects
                        </NavLink>
                        <NavLink className="nav-link" to="/education">
                            Education
                        </NavLink>
                        <NavLink className="nav-link" to="/contact">
                            Contact
                        </NavLink>
                    </Nav>
                </Navbar.Collapse>
        </Navbar>

        /*                <nav className={this.state.clicked ? "NavbarItemsDropDown" : "NavbarItems"}>
                        <div className="navbar-logo">
                            <h1>Tim Merker</h1>
                        </div>
                        <div className="menu-icon" onClick={this.handleClick}>
                            <i className={this.state.clicked ? "fa-solid fa-angle-up" : "fa-solid fa-angle-down"}/>
                        </div>
                        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
                            {MenuItems.map((item,index) => {
                                return (
                                    <li key={index}>
                                        <NavLink to={item.url} className={item.cName}>
                                            {item.title}
                                        </NavLink>
                                    </li>
                                )
                            })}
                        </ul>
                    </nav>*/
    )

}