import "./Education.css"
import React, {useState} from "react";
import {Container, Row, Col, Fade} from "react-bootstrap";
import picture1 from './picture1.jpg';
import earth from './earth.jpg';


const Education = () => {

    const [imageLoaded1, setImageLoaded1] = useState(false);
    const [imageLoaded2, setImageLoaded2] = useState(false);

    return (
        <>
            <Container className="mb-5">
                <Row className="mb-5 mt-5">
                    <h2 className="color_sec display-4 mb-6">M.Sc. Computer Science</h2>
                </Row>
                <Row className="sec">
                    <Col md={6}>
                        <p>During my master's program, I focused on <b>Artificial Intelligence</b>, <b>Data
                            Science</b>, <b>Software Development</b> and <b>Web Science</b>.
                            <br/>
                            I was able to sharpen my programming skills
                            in <b>Java</b>, <b>Python</b>, <b>C++</b>, <b>R</b>, <b>JavaScript</b>, <b>Scala</b>, <b>Haskell</b> and <b>Prolog</b>.
                            <br/>

                            To further develop my programming skills, I started as a working student in the <b>IT-Department</b> at <b>Debeka</b>.
                            I am passionate in <b>Software Development</b> in <b>Java</b> and gained valuable experience early on.
                            <br/>

                            In <b>Financial Data Science</b>, I was the project leader for the development of a stock
                            scanner in <b>R</b>.
                            Our group implemented different approaches for measuring the momentum of current price
                            developments and evaluated the results.
                            <br/>

                            In 2022, I attended the "Computational Social Science/Data Science" committee as
                            a <b>Student Representative</b> at the University Koblenz-Landau, with the task of
                            finding a knowledgeable and passionate
                            professor for the Department of Computer Science.

                            <br/>
                        </p>
                    </Col>
                    <Col md={{ span: 5, offset: 1 }} className="mt-2 align-self-center" >
                        <Fade in={imageLoaded1} dimension="width">
                            <div className="image-holder">
                            <img
                                 onLoad={() => setImageLoaded1(true)}
                                 src={picture1}
                                 alt="Computer Programming"/>
                            </div>
                        </Fade>
                    </Col>
                </Row>
                <Row className="mb-5 mt-3">
                    <h2 className="color_sec display-4 mb-6">B.Sc. Computer Science</h2>
                </Row>
                <Row className="sec">
                    <Col md={6}>
                        <p>

                            <a href="https://github.com/TimMerker/AutomatedReasoning/blob/master/thesis.pdf">My bachelor thesis</a> provides
                            a <b>Java</b> toolkit for automated reasoning and interpolation in propositional logic.
                            <br/>

                            Automated reasoning has many applications in <b>Verification</b> of <b>Safety-critical</b> programs and
                            finite-state systems. Interpolation is used to make consistency proofs in <b>Modular Databases</b>. <a href="https://github.com/TimMerker/AutomatedReasoning">This toolkit</a> can
                            be run in teaching mode for students and result mode for experienced users.
                            <br/>

                            From the beginning of my third semester,
                            I started helping out as a <b>Student Teaching Assistant</b> in the lecture <b>"Logic for Computer Science"</b> and
                            later in <b>"Computer Networks"</b>.
                            It was a great pleasure helping students to achieve their learning goals and watching them grow.
                            <br/>

                            I successfully passed lectures in Algorithms and Data Structures, Object Oriented Programming,
                            Functional and Logical Programming, IT-Security, Databases, Information Systems, Mathematics,
                            Theoretical Computer Science and many more.
                            <br/>
                        </p>
                    </Col>
                    <Col md={{ span: 5, offset: 1 }} className="mt-2">
                        <Fade in={imageLoaded2} dimension="width">
                            <div className="image-holder">
                            <img
                                onLoad={() => setImageLoaded2(true)}
                                alt ="Image"
                                src={earth}/>
                            </div>
                        </Fade>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Education;