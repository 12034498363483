import React, {useRef, useState} from "react";
import emailjs from "@emailjs/browser";
import {Container, Row, Col, Form, Fade} from "react-bootstrap";
import "./Contact.css"
import {contactConfig} from "./content_option";
import "bootstrap/dist/css/bootstrap-grid.min.css"
import {NavLink} from "react-router-dom";


export default function Contact() {
    const form = useRef();
    const success_Message = "Thanks for reaching out!"
    const [showResult, setShowResult] = useState(false);


    // Hide success message
    setTimeout(() => {
        setShowResult(false)
    }, 5000);

    const sendEmail = (e) => {
            e.preventDefault();


            emailjs.sendForm('service_6frknyp', 'contact_form', form.current, 'bSiVGVzEJabg782Su')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });
            e.target.reset();
            setShowResult(true);
        };

        return (
            <Container>
                <Row className="mb-5 mt-5">
                    <Col lg = "8">
                        <h1 className="color_sec display-4 mb-6">
                            Contact Me
                        </h1>
                    </Col>
                </Row>

                <Row className="sec">
                    <Col className="mb-5">
                        <h3 className="color_sec py-4">Get in touch</h3>
                        <h5>{contactConfig.title}</h5>
                        <p>{contactConfig.description}</p>
                        <address>
                            <p>Email: {contactConfig.email}</p>
                            <p>Phone: {contactConfig.phone}</p>
                            <br />
                        </address>
                    </Col>
                    <Col lg='7' className="d-flex align-items-center">
                        <Form className="contact__form w-100" ref={form} onSubmit={sendEmail}>
                            <Row>
                                <input className type="hidden" name="contact_number" value={Math.random() * 100000 | 0}/>
                                <Col lg ="6" className="form-group">
                                    <input className="form-control"
                                           id ="name"
                                           name="full_name"
                                           placeholder="Name"
                                           type="text"
                                           required minLength={3} maxLength={40}/>
                                </Col>
                                <Col lg ="6" className="form-group">
                                    <input
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        placeholder="Email"
                                        type="email"
                                        required minLength={4} maxLength={40}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                            <textarea className="form-control"
                                      id="message"
                                      name="message"
                                      placeholder="Message"
                                      rows="5"
                                      required minLength={10} maxLength={100}
                            ></textarea>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                    <p>Your data is necessary for processing your inquiry and will be used only for this
                                        purpose. You can find our <NavLink to="/legal-notice">data privacy information</NavLink> here. </p>
                                    <Form.Group className="mb-3">
                                        <Form.Check
                                            required
                                            name="terms"
                                            label="Agree to terms and conditions"
                                            feedbackType="invalid"
                                            id="validationFormik0"
                                        />
                                    </Form.Group>

                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Fade in={showResult}  timeout={4000}>
                                <h4 className="color_sec  resultMessage">{showResult ? success_Message : null}</h4>
                                </Fade>
                            </Row>
                            <Row>
                                <Col className="form-group mb-5">
                                    <button className="btn btn-outline-primary" type="submit" value="Send">Send</button>
                                </Col>
                            </Row>

                        </Form>

                    </Col>


                </Row>
            </Container>
            /*<div className="container">
                <div className="text-container">
                    <div className="title">Contact</div>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonu my eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                        sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                        Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                        consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                        At vero eos et accusam et justo duo dolores et ea rebum.
                        Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                </div>
                <div className="form-container">
                <form className="form" ref={form} onSubmit={sendEmail}>
                    <input className type="hidden" name="contact_number" value={Math.random() * 100000 | 0}/>
                    <label className="label">Name</label>
                    <input className="input100" type="text" name="full_name"/>
                    <label className="label">Email</label>
                    <input className="input100" type="email" name="email"/>
                    <label className="label">Subject</label>
                    <input className="input100" type="text" name="subject"/>
                    <label className="label">Message</label>
                    <textarea name="message"/>
                    <button className="input100" type="submit" value="Send">SUBMIT</button>
                    <div className="resultMessage">{showResult ? success_Message : null}</div>
                </form>
                </div>
            </div>*/
        )
};
